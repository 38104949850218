import {
  CustomField,
  CustomFieldDataType,
  CustomFieldType,
  CustomForm,
  CustomRequirement,
  CustomSection,
  DocumentUpload,
  QuestionnaireResponse,
  SmartRule,
  SmartRuleConditionStatement,
  TemplateResponse
} from '@gcv/shared';
import { Box } from '@mui/material';
import * as React from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { FieldValues } from 'react-hook-form';
import { getFiBankStore } from 'stores/FiBankStore';
import {
  Button,
  Card,
  Container,
  ContainerItem,
  CustomFormReducer,
  Drag,
  Drop,
  FieldDeleteModal,
  FieldModal,
  Hyperlink,
  SectionAddButton,
  SectionDeleteModal,
  SectionDisplay,
  SectionModal,
  Spinner,
  Text,
  ZeroState
} from 'ui';
import { v4 as uuid } from 'uuid';
import { PreviewCustomFormModal } from './preview-custom-form-modal';
import { RemoveSmartRulesFirstModal } from './remove-smart-rules-first-modal';
import { SmartRuleModal, SmartRuleState } from './smart-rule-modal';
import { SmartRuleSectionModal } from './smart-rule-section-modal';
import { getSnackbarStore } from 'stores/SnackBarStore';
import { uploadGcvSharedDocumentToS3 } from 'util/s3.util';
import { DocumentsApi } from 'api';

interface Props {
  customForm: CustomForm;
  description: string;
  formStatus: 'draft' | 'published';
  isLoading: boolean;
  title: string;
  zeroStateTitle: string;
  zeroStateSubTitle?: string;
  showPreviewButton?: boolean;
  previewTitle?: string;
  previewSubtitle?: string | React.ReactNode;
  viewOnly?: boolean;
  isUpdatingContent?: boolean;
  viewOnlyTooltip?: string;
  addSection: (
    section: CustomSection,
    action: DragActions,
    isAddingAnotherField?: boolean
  ) => Promise<CustomRequirement>;
  editSection: (
    section: CustomSection,
    action: DragActions,
    isAddingAnotherField?: boolean
  ) => Promise<TemplateResponse | QuestionnaireResponse>;
  reorderSections: (sectionIds: string[]) => Promise<TemplateResponse>;
  deleteSection: (section: CustomSection, action: DragActions) => Promise<TemplateResponse>;
  onError: (message: string | JSX.Element) => void;
  noDefaultFields?: boolean;
  setUpdatingContent?: (value: boolean) => void;
}

export type DragActions =
  | 'ADD_SECTION'
  | 'EDIT_SECTION'
  | 'MOVE_SECTION'
  | 'DELETE_SECTION'
  | 'ADD_FIELD'
  | 'EDIT_FIELD'
  | 'MOVE_FIELD'
  | 'DELETE_FIELD';

const dragReducer = (
  state: CustomForm,
  action: {
    type: DragActions;
    payload: {
      elementId?: string;
      element?: FieldValues;
      from?: string;
      fromIndex?: number;
      to?: string;
      toIndex?: number;
      indexToAddItem?: number;
    };
  }
) => {
  const customFormReducer = new CustomFormReducer(state);

  switch (action.type) {
    case 'ADD_SECTION':
      return customFormReducer.addSection(action.payload.element as CustomSection);
    case 'EDIT_SECTION':
      return customFormReducer.editSection(action.payload.element as CustomSection);
    case 'MOVE_SECTION':
      return customFormReducer.moveSection(Number(action.payload.fromIndex), Number(action.payload.toIndex));
    case 'DELETE_SECTION':
      return customFormReducer.deleteSection(action.payload.element as CustomSection);
    case 'ADD_FIELD':
      return customFormReducer.addField(action.payload.element as CustomField, action.payload.indexToAddItem);
    case 'EDIT_FIELD':
      return customFormReducer.editField(action.payload.element as CustomField);
    case 'MOVE_FIELD':
      return customFormReducer.moveField(
        action.payload.from ?? '',
        Number(action.payload.fromIndex),
        action.payload.to ?? '',
        Number(action.payload.toIndex),
        action.payload.elementId
      );
    case 'DELETE_FIELD':
      return customFormReducer.deleteField(action.payload.element as CustomField);
    default:
      return { ...state };
  }
};

export const FormDisplay: React.FC<Props> = (props) => {
  const [state, dispatch] = React.useReducer(dragReducer, props.customForm);

  const [activeField, setActiveField] = React.useState<CustomField>();
  const [fieldModalOpen, setFieldModalOpen] = React.useState(false);
  const [fieldDeleteModalOpen, setFieldDeleteModalOpen] = React.useState(false);
  const [activeSection, setActiveSection] = React.useState<CustomSection>();
  const [sectionModalOpen, setSectionModalOpen] = React.useState(false);
  const [sectionDeleteModalOpen, setSectionDeleteModalOpen] = React.useState(false);
  const [smartRulesModalOpen, setSmartRulesModalOpen] = React.useState(false);
  const [smartRuleCheckModalOpen, setSmartRuleCheckModalOpen] = React.useState(false);
  const [deletingSmartRuleTarget, setDeletingSmartRuleTarget] = React.useState(false);
  const [ruleState, setRuleState] = React.useState(SmartRuleState.New);
  const [smartRuleList, setSmartRuleList] = React.useState<(CustomSection | CustomField)[]>([]);
  const [smartRulesSectionModalOpen, setSmartRulesSectionModalOpen] = React.useState(false);
  const [showPreview, setShowPreview] = React.useState(false);
  const [defaultSection, setDefaultSection] = React.useState<CustomSection | undefined>();
  const [fieldData, setFieldData] = React.useState<FieldValues | undefined>(undefined);
  const [sectionData, setSectionData] = React.useState<FieldValues | undefined>(undefined);
  const [loadingSection, setLoadingSection] = React.useState(false);
  const [fiDocuments, setFiDocuments] = React.useState<DocumentUpload[]>([]);
  const [duplicateItemIds, setDuplicateItemIds] = React.useState<string[]>([]);

  const fiBankStore = getFiBankStore();
  const documentsApi: DocumentsApi = new DocumentsApi();
  const snackbarStore = getSnackbarStore();

  const fetchFiDocuments = async () => {
    const documents = await documentsApi.getDocuments(fiBankStore.bank.id);
    setFiDocuments(documents);
  };

  React.useEffect(() => {
    fetchFiDocuments();
  }, []);

  React.useEffect(() => {
    if (!state.sections.length && !props.noDefaultFields) {
      initializeDefaultSection().then(() => {
        initializeDefaultField(state.sections[0]);
      });
    }
  }, [state.sections.length]);

  const initializeDefaultSection = async () => {
    const customRequirement = await props.addSection(
      {
        id: '',
        label: 'Introduction',
        named_section: true,
        order: 0,
        fields: []
      },
      'ADD_SECTION'
    );

    dispatch({
      type: 'ADD_SECTION',
      payload: {
        element: {
          ...customRequirement.custom_section
        }
      }
    });

    setActiveSection(undefined);
    setActiveField(undefined);
  };

  const initializeDefaultField = async (section: CustomSection) => {
    if (section && section.id) {
      const defaultField = {
        id: uuid(),
        type: CustomFieldType.TextSingleLine,
        dataType: CustomFieldDataType.Text,
        label: 'This is an example field that you can edit or delete.',
        section: section.id
      };

      props.editSection({ ...section, fields: [defaultField] }, 'EDIT_SECTION');

      dispatch({
        type: 'ADD_FIELD',
        payload: {
          element: defaultField
        }
      });

      setActiveSection(undefined);
      setActiveField(undefined);
    }
  };

  const isZeroStateVisible = React.useMemo(() => {
    const hasOnlyEmptyUnnamedSection = () => {
      return state.sections.length === 1 && defaultSection?.fields.length === 0;
    };

    // This is a temporary state during the form's initial load, until we initialize the unnamed section.
    const empty = !state.sections?.length;

    return empty ? true : hasOnlyEmptyUnnamedSection();
  }, [state.sections?.length, defaultSection?.fields.length]);

  const uploadDocuments = async (files: File[]) => {
    if (files.length) {
      const uploadedDocs: DocumentUpload[] = [];
      try {
        for (let index = 0; index < files.length; index++) {
          const file = files[index];
          const docId = await uploadGcvSharedDocumentToS3(file, `${fiBankStore.bank.id}`);
          const document = await documentsApi.putDocument(file.name, docId, fiBankStore.bank.id);
          uploadedDocs.push(document);
        }
        return uploadedDocs;
      } catch (error) {
        snackbarStore.showErrorSnackbarMessage(`There was an issue uploading files.`);
      }
    }
  };

  const saveField = async (
    data: FieldValues,
    isCreatingAnotherField: boolean,
    isCreatingSmartRule: boolean
  ) => {
    let section = state.sections.find((s) => s.id === data.section);

    // check for a dynamically created section that is sitting in active
    if (!section) {
      section = activeSection && state.sections.find((s) => s.id === activeSection.id);
      data.section = section?.id;
    }

    if (activeField) {
      dispatch({
        type: 'EDIT_FIELD',
        payload: {
          element: {
            ...data
          }
        }
      });

      if (section && isFieldSmartRuleTargetForSection(section?.id, data.id)) {
        props.onError(
          <div>
            A field cannot be moved into a section that has smart rule dependencies.{' '}
            <Hyperlink
              newTab
              label="Learn more here."
              url="https://support.greencheckverified.com/knowledge/using-smart-rules-in-custom-fields"
            />
          </div>
        );
      }
    } else {
      const fieldid = uuid();
      data.id = fieldid;
      dispatch({
        type: 'ADD_FIELD',
        payload: {
          element: {
            ...data,
            id: fieldid
          }
        }
      });
    }

    window.setTimeout(() => {
      if (section) {
        props.editSection(section, activeField ? 'EDIT_FIELD' : 'ADD_FIELD', isCreatingAnotherField);
      }
    }, 0);

    if (isCreatingSmartRule) {
      // instead of using active field which has weird side effects set a seperate variable for the modal to read with the data
      setFieldData(data);
      setSmartRulesModalOpen(true);
    } else {
      setActiveSection(undefined);
      setActiveField(undefined);
    }
    fetchFiDocuments();
  };

  const saveSmartRuleOnField = async (data: SmartRule, sectionId: string, field: FieldValues) => {
    dispatch({
      type: 'EDIT_FIELD',
      payload: {
        element: {
          ...field,
          smart_rule: data
        }
      }
    });
    const section =
      sectionId === ''
        ? state.sections.find((s) => !s.named_section)
        : state.sections.find((s) => s.id === sectionId);
    if (section) {
      window.setTimeout(() => {
        props.editSection(section, 'EDIT_FIELD');
      }, 0);
    }
    setSmartRulesModalOpen(false);
    setFieldData(undefined);
    setActiveField(undefined);
    setActiveSection(undefined);
  };

  const saveSection = async (data: FieldValues, isCreatingSmartRule: boolean) => {
    const sectionInfo = {
      id: '',
      named_section: true,
      label: data.label,
      description: data.description,
      order: state.sections.length,
      fields: []
    };

    if (activeSection) {
      dispatch({
        type: 'EDIT_SECTION',
        payload: {
          element: { ...data }
        }
      });

      const section = state.sections.find((s) => s.id === data.id);

      if (section) {
        window.setTimeout(() => {
          props.editSection(section, 'EDIT_SECTION');
        }, 0);
      }
    } else {
      if (isCreatingSmartRule) {
        setSectionData(sectionInfo);
        setSmartRulesSectionModalOpen(true);
        setLoadingSection(true);
      }

      const customRequirement = await props.addSection(sectionInfo, 'ADD_SECTION');

      dispatch({
        type: 'ADD_SECTION',
        payload: {
          element: {
            ...customRequirement.custom_section
          }
        }
      });

      if (isCreatingSmartRule) {
        setSectionData(customRequirement.custom_section);
      }
    }

    if (isCreatingSmartRule) {
      setLoadingSection(false);
    } else {
      setActiveField(undefined);
    }
  };

  const isFieldSmartRuleTarget = (field: FieldValues | undefined) => {
    let isTarget = false;
    const smartRuleSubjects: (CustomField | CustomSection)[] = [];
    if (field) {
      for (const section of state.sections) {
        section.fields.findIndex((subject) => {
          subject.smart_rule?.conditions.forEach((rule) => {
            if (rule.targetFieldId === field.id) {
              smartRuleSubjects.push(subject);
              isTarget = true;
            }
          });
        });
        section.smart_rule?.conditions.forEach((rule) => {
          if (rule.targetFieldId === field.id) {
            smartRuleSubjects.push(section);
            isTarget = true;
          }
        });
      }
    }
    return { isTarget, smartRuleSubjects };
  };

  const saveSmartRuleOnSection = async (data: SmartRule, sectionData: FieldValues) => {
    const section = state.sections.find((s) => s.id === sectionData.id);
    dispatch({
      type: 'EDIT_SECTION',
      payload: {
        element: {
          ...section,
          smart_rule: data
        }
      }
    });
    const updatedSection = state.sections.find((s) => s.id === sectionData.id);
    if (updatedSection) {
      window.setTimeout(() => {
        props.editSection(updatedSection, 'EDIT_SECTION');
      }, 0);
    }
    setSmartRulesSectionModalOpen(false);
    setSectionData(undefined);
    setActiveField(undefined);
    setActiveSection(undefined);
  };

  const isFieldSmartRuleTargetForSection = (sectionId: string, fieldId: string) => {
    let isTarget = false;
    const section = state.sections.find((s) => s.id === sectionId);
    if (section?.smart_rule) {
      section.smart_rule?.conditions.forEach((rule) => {
        if (rule.targetFieldId === fieldId) {
          isTarget = true;
        }
      });
    }
    return isTarget;
  };

  const onDragEnd = React.useCallback(async (result: DropResult) => {
    if (result.reason === 'DROP') {
      if (!result.destination) {
        return;
      }

      const actionType = result.type === 'SECTION' ? 'MOVE_SECTION' : 'MOVE_FIELD';

      dispatch({
        type: actionType,
        payload: {
          from: result.source.droppableId,
          fromIndex: result.source.index,
          to: result.destination.droppableId,
          toIndex: result.destination.index,
          elementId: result.draggableId
        }
      });

      if (isFieldSmartRuleTargetForSection(result.destination.droppableId, result.draggableId)) {
        props.onError(
          <div>
            A field cannot be moved into a section that has smart rule dependencies.{' '}
            <Hyperlink
              newTab
              label="Learn more here."
              url="https://support.greencheckverified.com/knowledge/using-smart-rules-in-custom-fields"
            />
          </div>
        );
      }

      if (actionType === 'MOVE_SECTION') {
        window.setTimeout(() => {
          props.reorderSections(state.sections.sort((a, b) => (a.order > b.order ? 1 : -1)).map((s) => s.id));
        }, 0);
      } else {
        const fromSection =
          result.source.droppableId === 'field-droppable'
            ? state.sections.find((s) => !s.named_section)
            : state.sections.find((s) => s.id === result.source.droppableId);

        const toSection =
          result.destination.droppableId === 'field-droppable'
            ? state.sections.find((s) => !s.named_section)
            : state.sections.find((s) => s.id === result.destination?.droppableId);

        window.setTimeout(async () => {
          if (fromSection) {
            // do these updates sequentially to prevent transactional errors in dynamo
            await props.editSection(fromSection, actionType);
            if (toSection) {
              props.editSection(toSection, actionType);
            }
          }
        }, 0);
      }
    }
  }, []);

  const removeRuleFromField = (field: FieldValues) => {
    delete field.smart_rule;

    dispatch({
      type: 'EDIT_FIELD',
      payload: {
        element: {
          ...field
        }
      }
    });
    const section =
      field.section === ''
        ? state.sections.find((s) => !s.named_section)
        : state.sections.find((s) => s.id === field.section);
    if (section) {
      window.setTimeout(() => {
        props.editSection(section, 'EDIT_FIELD');
      }, 0);
    }

    setSmartRulesModalOpen(false);
    setFieldData(undefined);
    setActiveField(undefined);
    setActiveSection(undefined);
  };

  const removeRuleFromSection = (section: FieldValues) => {
    delete section.smart_rule;

    dispatch({
      type: 'EDIT_SECTION',
      payload: {
        element: {
          ...section
        }
      }
    });
    const sectionInState = state.sections.find((s) => s.id === section.id);
    if (sectionInState) {
      window.setTimeout(() => {
        props.editSection(sectionInState, 'EDIT_FIELD');
      }, 0);
    }

    setSmartRulesSectionModalOpen(false);
    setSectionData(undefined);
    setActiveField(undefined);
    setActiveSection(undefined);
  };

  const mapSections = () => {
    const sections = [{ label: '+ Add New Section', value: 'create-section' }];

    return sections.concat(
      state.sections?.map((section) => {
        return { label: section.label, value: section.id };
      }) ?? []
    );
  };

  const openFieldModal = (isOpen: boolean, fieldId?: string, sectionId?: string) => {
    if (fieldId) {
      let field: CustomField | undefined;

      for (const section of state.sections) {
        field = section.fields?.find((f) => f.id === fieldId);

        if (field) {
          break;
        }
      }

      setActiveField(field);
    }

    if (sectionId) {
      const section = state.sections?.find((s) => s.id === sectionId);
      setActiveSection(section);
    }

    setFieldModalOpen(isOpen);
  };

  const openFieldDeleteModal = (isOpen: boolean, fieldId: string) => {
    let field: CustomField | undefined;

    for (const section of state.sections) {
      field = section.fields?.find((f) => f.id === fieldId);
      if (field) {
        setActiveField(field);
        setActiveSection(section);
        break;
      }
    }
    const { isTarget, smartRuleSubjects } = isFieldSmartRuleTarget(field);
    if (isTarget) {
      setDeletingSmartRuleTarget(true);
      setSmartRuleList(smartRuleSubjects);
      setSmartRuleCheckModalOpen(true);
    } else {
      setFieldDeleteModalOpen(isOpen);
    }
  };

  const openSectionModal = (isOpen: boolean, sectionId?: string) => {
    if (sectionId) {
      const section = state.sections?.find((s) => s.id === sectionId);
      setActiveSection(section);
    }

    setSectionModalOpen(isOpen);
  };

  const openSectionDeleteModal = (isOpen: boolean, sectionId: string) => {
    const section = state.sections?.find((s) => s.id === sectionId);
    setActiveSection(section);

    setSectionDeleteModalOpen(isOpen);
  };

  const openSmartRuleModal = (field: FieldValues) => {
    const { isTarget, smartRuleSubjects } = isFieldSmartRuleTarget(field);

    setFieldData(field);
    if (isTarget) {
      setDeletingSmartRuleTarget(false);
      setSmartRuleList(smartRuleSubjects);
      setSmartRuleCheckModalOpen(true);
    } else {
      setRuleState(SmartRuleState.Existing);
      setSmartRulesModalOpen(true);
    }
  };

  const openSmartRuleModalSection = (section: FieldValues) => {
    const { isTarget, smartRuleSubjects } = isFieldSmartRuleTarget(section);

    if (isTarget) {
      setSmartRuleList(smartRuleSubjects);
      setSmartRuleCheckModalOpen(true);
    } else {
      setSectionData(section);
      setRuleState(SmartRuleState.Existing);
      setSmartRulesSectionModalOpen(true);
    }
  };

  // this function is to duplicate attachments
  // returns a new copy of attachments with new DocumentUpload records using same s3 key
  const duplicateAttachments = async (attachments: { file_name: string; id: string }[]) => {
    const oldDocs: { name: string; s3Key: string }[] = [];
    attachments.forEach((doc: { file_name: string; id: string }) => {
      const fiDoc = fiDocuments.find((fd) => fd.id === doc.id);
      if (fiDoc) {
        oldDocs.push({
          name: fiDoc.file_name,
          s3Key: fiDoc.s3_key
        });
      }
    });

    const newAttachments = await Promise.all(
      oldDocs.map(async (doc) => {
        const docUploaded = await documentsApi.putDocument(doc.name, doc.s3Key, fiBankStore.bank.id);
        return { file_name: docUploaded.file_name, id: docUploaded.id };
      })
    );
    return newAttachments;
  };

  const duplicateFieldOrSection = async (type: 'field' | 'section', data: FieldValues) => {
    if (props.setUpdatingContent) {
      props.setUpdatingContent(true);
    }
    const duplicatedIds = []; // this array is needed to highlight the duplicated field or section

    if (type === 'section') {
      // cannot clone entire section at once
      // fields need sectionID as a parameter. We get sectionID only after adding a section
      // first section is added and then fields are added to section and is edited

      // cloned section should come right after the source section
      // for that, initially section is added at the end, and used to MOVE_SECTION action
      // to bring the section below the original section

      const sectionInfo: CustomSection = {
        id: '',
        named_section: true,
        label: `Copy of ${data.label}`,
        description: data.description,
        order: state.sections.length, // adding section at the end
        smart_rule: data.smart_rule,
        fields: []
      };

      // adding the section first
      const customRequirement = await props.addSection(sectionInfo, 'ADD_SECTION');
      dispatch({
        type: 'ADD_SECTION',
        payload: {
          element: {
            ...customRequirement.custom_section
          }
        }
      });

      // find order of source and add the cloned section below it.
      // Order 0 is considered as false and will never go inside the if conditon
      // hence undefined is used to check
      const order = state.sections.find((s) => s.id === data.id)?.order;
      if (order !== undefined && state.sections.length - 1 !== order + 1) {
        dispatch({
          type: 'MOVE_SECTION',
          payload: {
            from: 'section-droppable',
            fromIndex: state.sections.length - 1,
            to: 'section-droppable',
            toIndex: order + 1,
            elementId: customRequirement.custom_section.id
          }
        });

        await props.reorderSections(
          state.sections.sort((a, b) => (a.order > b.order ? 1 : -1)).map((s) => s.id)
        );
      }

      // to highlight the section
      duplicatedIds.push(customRequirement.custom_section.id);

      if (data.fields && data.fields?.length) {
        // this map is used to change the fieldIds inside a smart rule,
        // the copied object smart rule points to the field of source section's fields
        // so source field ids to new field ids map is created to change the targetField id in smart rule
        const oldNewFieldIdMap: { [id: string]: string } = {};
        let fields = JSON.parse(JSON.stringify(data.fields));

        // this is a promise to clone the attachments of File Upload field & Display content field
        // altering the source field attachments should not alter the cloned field attachments
        // hence new DocumentUpload record is created with the existing s3 key
        fields = await Promise.all(
          fields.map(async (field: FieldValues) => {
            const fieldId = uuid();
            duplicatedIds.push(fieldId);
            oldNewFieldIdMap[field.id] = fieldId;
            const newField = {
              ...field,
              id: fieldId,
              label: `Copy of ${field.label}`,
              section: customRequirement.custom_section.id,
              attachments: [] as { file_name: string; id: string }[]
            };
            if (field.attachments) {
              newField.attachments = await duplicateAttachments(field.attachments);
            }
            return newField;
          }) as CustomField[]
        );

        // this map is to replate the targetFieldId inside a smart rule with newly cloned field id
        fields = fields.map((field: FieldValues) => {
          const newField = { ...field };
          if (newField.smart_rule) {
            const conditions = newField.smart_rule.conditions.map((c: SmartRuleConditionStatement) => {
              const newCondition = { ...c };
              newCondition.targetFieldId = oldNewFieldIdMap[c.targetFieldId];
              return newCondition;
            });
            newField.smart_rule.conditions = conditions;
          }
          return newField;
        });

        // section from custom requirement doesn't have the updated order
        // order was updated above using MOVE_SECTION action
        // latest order of the section will be in state
        const clonedSection =
          state.sections.find((s) => s.id === customRequirement.custom_section.id) ??
          customRequirement.custom_section;
        // add's cloned fields to section and edit the section
        const sectionWithFields: CustomSection = {
          ...clonedSection,
          fields
        };
        dispatch({
          type: 'EDIT_SECTION',
          payload: {
            element: { ...sectionWithFields }
          }
        });

        const section = state.sections.find((s) => s.id === customRequirement.custom_section.id);
        if (section) {
          window.setTimeout(() => {
            props.editSection(section, 'EDIT_SECTION');
            duplicatedIds.push(section.id);
          }, 0);
        }
      }
    }
    if (type === 'field') {
      const fieldId = uuid();
      const field = {
        ...data,
        id: fieldId,
        type: data.type,
        label: `Copy of ${data.label}`,
        attachments: [] as { file_name: string; id: string }[]
      };
      // creates a new copy of attachments
      if (data.attachments) {
        field.attachments = await duplicateAttachments(data.attachments);
      }

      const section = state.sections.find((s) => s.id === data.section);

      if (section) {
        // cloned field needs to be added right after the source field
        // find the index of source field, splice the array and add the field there
        const fields = [...section.fields];
        const indexToBeAdded = fields.findIndex((f) => f.id === data.id) + 1;
        fields.splice(indexToBeAdded, 0, field);

        await props.editSection({ ...section, fields }, 'EDIT_SECTION');

        dispatch({
          type: 'ADD_FIELD',
          payload: {
            element: field,
            indexToAddItem: indexToBeAdded
          }
        });
      }

      duplicatedIds.push(fieldId);
    }

    setDuplicateItemIds(duplicatedIds);
    // // this function is called to fetch the documents again as the cloned documents are not available yet in fiDocuments
    await fetchFiDocuments();

    if (props.setUpdatingContent) {
      props.setUpdatingContent(false);
    }
    snackbarStore.showSuccessSnackbarMessage(
      `${type === 'field' ? 'Field' : 'Section'} duplicated successfully.`
    );
    setDuplicateItemIds([]);
  };

  if (props.isLoading) {
    return <Spinner />;
  }

  return (
    <Card
      header={{
        type: 'h2',
        title: props.title,
        subtitle: props.description,
        actions: (
          <Container padding={0}>
            {props.showPreviewButton && !isZeroStateVisible && (
              <Button
                label="Preview"
                color="primary"
                onClick={() => {
                  setShowPreview(!showPreview);
                }}
                style={{
                  marginRight: props.viewOnly ? '0' : '1rem'
                }}
                dataCy="preview-button"
              />
            )}
            {!props.viewOnly && (
              <Button
                label="Add New Field"
                color="primary"
                onClick={() => {
                  setActiveField(undefined);
                  setFieldModalOpen(true);
                }}
                dataCy="add-new-field-button"
              />
            )}
          </Container>
        )
      }}
      contentOverflow="visible"
    >
      {isZeroStateVisible ? (
        <ZeroState
          title={props.zeroStateTitle}
          icon="/img/empty-states/form-builder.svg"
          subTitle={
            props.viewOnly ? null : (
              <Container align="center" padding={0}>
                <ContainerItem padding={'0.5rem 0.25rem 0.5rem 0.25rem'}>
                  <Text
                    type="body2"
                    content={
                      props.zeroStateSubTitle
                        ? props.zeroStateSubTitle
                        : 'Start building your form by creating a'
                    }
                  />
                </ContainerItem>
                <ContainerItem padding={0}>
                  <Hyperlink
                    label="New Field"
                    onClick={() => setFieldModalOpen(true)}
                    dataCy="new-field-link"
                    style={{
                      padding: 0
                    }}
                  />
                </ContainerItem>
                <ContainerItem padding={'0.5rem 0.25rem 0.5rem 0.25rem'}>
                  <Text type="body2" content="or" />
                </ContainerItem>
                <ContainerItem padding={0}>
                  <Hyperlink
                    label="New Section"
                    onClick={() => setSectionModalOpen(true)}
                    dataCy="new-section-link"
                    style={{
                      padding: 0
                    }}
                  />
                </ContainerItem>
              </Container>
            )
          }
          dataCy="form-display-zero-state"
        />
      ) : props.viewOnly ? (
        <>
          {state.sections
            ?.sort((a, b) => (a.order > b.order ? 1 : -1))
            .map((section, index) => {
              if (!section.named_section) {
                return;
              }

              return (
                <Box sx={{ padding: '0 0 8px 0' }}>
                  <SectionDisplay
                    dragHandleProps={undefined}
                    openFieldModal={openFieldModal}
                    openFieldDeleteModal={openFieldDeleteModal}
                    openSectionModal={openSectionModal}
                    openSectionDeleteModal={openSectionDeleteModal}
                    openSmartRuleModal={openSmartRuleModal}
                    openSmartRuleModalForSection={openSmartRuleModalSection}
                    section={section}
                    viewOnly={props.viewOnly}
                    duplicateSection={duplicateFieldOrSection}
                    duplicateItemIds={duplicateItemIds}
                    viewOnlyTooltip={props.viewOnlyTooltip}
                  />
                </Box>
              );
            })}
        </>
      ) : (
        <Box>
          <DragDropContext onDragEnd={onDragEnd}>
            <Drop id="section-droppable" type="SECTION">
              {state.sections
                ?.sort((a, b) => (a.order > b.order ? 1 : -1))
                .map((section, index) => {
                  if (!section.named_section) {
                    return;
                  }

                  return (
                    <Drag key={section.id} id={section.id} index={index}>
                      {(dragHandle) => (
                        <SectionDisplay
                          dragHandleProps={dragHandle}
                          openFieldModal={openFieldModal}
                          openFieldDeleteModal={openFieldDeleteModal}
                          openSectionModal={openSectionModal}
                          openSectionDeleteModal={openSectionDeleteModal}
                          openSmartRuleModal={openSmartRuleModal}
                          openSmartRuleModalForSection={openSmartRuleModalSection}
                          section={section}
                          viewOnly={props.viewOnly}
                          duplicateSection={duplicateFieldOrSection}
                          duplicateItemIds={duplicateItemIds}
                        />
                      )}
                    </Drag>
                  );
                })}
            </Drop>
          </DragDropContext>

          {!props.viewOnly && <SectionAddButton onClick={setSectionModalOpen} />}
        </Box>
      )}
      {showPreview && (
        <PreviewCustomFormModal
          isOpen={true}
          onClose={() => {
            setShowPreview(!showPreview);
          }}
          sections={state.sections}
          timezone={fiBankStore.bank.iana_timezone}
          title={props.previewTitle || ''}
          subTitle={props.previewSubtitle}
        />
      )}
      {fieldModalOpen && (
        <FieldModal
          field={activeField}
          formStatus={props.formStatus}
          isOpen={fieldModalOpen}
          onSave={saveField}
          section={activeSection ? activeSection.id : ''}
          sectionOptions={mapSections()}
          onSectionSelect={(value: string) => {
            if (!value || value === 'create-section') {
              setActiveSection(undefined);
              setSectionModalOpen(true);
            } else {
              setActiveSection(state.sections.find((s) => s.id === value));
            }
          }}
          setIsOpen={(isOpen: boolean, smartRules: boolean) => {
            setFieldModalOpen(isOpen);
            if (!isOpen && !smartRules) {
              setActiveField(undefined);
            }
          }}
          isLoading={props.isUpdatingContent}
          formFields={state.sections.filter((s) => s.named_section).flatMap((section) => section.fields)}
          optionsLocked={isFieldSmartRuleTarget(activeField)}
          uploadDocuments={uploadDocuments}
          fiDocuments={fiDocuments}
        />
      )}
      {fieldDeleteModalOpen && (
        <FieldDeleteModal
          isOpen={fieldDeleteModalOpen}
          setIsOpen={(isOpen: boolean) => {
            setFieldDeleteModalOpen(isOpen);
            if (!isOpen) {
              setActiveField(undefined);
              setActiveSection(undefined);
            }
          }}
          onSave={() => {
            if (activeSection && activeField) {
              dispatch({ type: 'DELETE_FIELD', payload: { element: { ...activeField } } });

              if (activeField.attachments) {
                activeField.attachments.forEach((file) => {
                  documentsApi.deleteDocument(fiBankStore.bank.id, file.id);
                });
              }

              window.setTimeout(() => {
                props.editSection(activeSection, 'DELETE_FIELD');
              }, 0);
            }
          }}
        />
      )}
      {sectionModalOpen && (
        <SectionModal
          isOpen={sectionModalOpen}
          setIsOpen={(isOpen: boolean) => {
            setSectionModalOpen(isOpen);
            if (!isOpen) {
              setActiveSection(undefined);
            }
          }}
          onSave={saveSection}
          section={activeSection}
          formFields={state.sections.flatMap((section) => section.fields)}
        />
      )}
      {sectionDeleteModalOpen && (
        <SectionDeleteModal
          isOpen={sectionDeleteModalOpen}
          setIsOpen={(isOpen: boolean) => {
            setSectionDeleteModalOpen(isOpen);
            if (!isOpen) {
              setActiveField(undefined);
              setActiveSection(undefined);
            }
          }}
          onSave={async () => {
            if (activeSection) {
              let section = state.sections.find((s) => !s.named_section);

              if (!section) {
                const customRequirement = await props.addSection(
                  {
                    id: '',
                    named_section: false,
                    label: '',
                    description: '',
                    order: state.sections.length,
                    fields: []
                  },
                  'ADD_SECTION'
                );

                dispatch({
                  type: 'ADD_SECTION',
                  payload: {
                    element: {
                      ...customRequirement.custom_section
                    }
                  }
                });

                section = state.sections.find((s) => !s.named_section);
              }

              if (section) {
                dispatch({ type: 'DELETE_SECTION', payload: { element: { ...activeSection } } });
                props.editSection(section, 'DELETE_SECTION').then(() => {
                  props.deleteSection(activeSection, 'DELETE_SECTION');
                });
              }
            }
          }}
        />
      )}
      {smartRulesModalOpen && (
        <SmartRuleModal
          isOpen={smartRulesModalOpen}
          field={fieldData as FieldValues}
          formFields={state.sections.flatMap((section) => section.fields)}
          onSave={saveSmartRuleOnField}
          onCancel={() => {
            setSmartRulesModalOpen(false);
            setActiveField(undefined);
            setActiveSection(undefined);
          }}
          onRemove={(field) => {
            setSmartRulesModalOpen(false);
            removeRuleFromField(field);
          }}
          ruleState={ruleState}
        />
      )}
      {smartRulesSectionModalOpen && (
        <SmartRuleSectionModal
          isOpen={smartRulesSectionModalOpen}
          section={sectionData as FieldValues}
          formFields={state.sections.flatMap((section) => section.fields)}
          onSave={saveSmartRuleOnSection}
          onCancel={() => {
            setSmartRulesSectionModalOpen(false);
            setSectionData(undefined);
            setActiveField(undefined);
            setActiveSection(undefined);
          }}
          onRemove={(section) => {
            setSmartRulesSectionModalOpen(false);
            removeRuleFromSection(section);
          }}
          ruleState={ruleState}
          loadingSection={loadingSection}
        />
      )}
      {smartRuleCheckModalOpen && (
        <RemoveSmartRulesFirstModal
          isOpen={smartRuleCheckModalOpen}
          field={activeField}
          setIsOpen={(isOpen: boolean) => {
            setSmartRuleCheckModalOpen(isOpen);
          }}
          fieldList={smartRuleList}
          checkReason={
            deletingSmartRuleTarget
              ? 'To delete this field, first remove the connected smart rules:'
              : fieldData?.smart_rule
              ? 'In edit the smart rule for this field, you must first remove the connected smart rules:'
              : 'To create a smart rule for this field, first remove the connected smart rules:'
          }
        />
      )}
    </Card>
  );
};
