import { useAuth0 } from '@auth0/auth0-react';
import { GreenCheckAwsEnvironmentName, PayqwickUserSession } from '@gcv/shared';
import { useRollbar } from '@rollbar/react';
import { UsersApi } from 'api';
import ApiAuth0Session, {
  Auth0SessionToken,
  GCApplicationAccess,
  GreenCheckDirectApplication
} from 'api/api-util/api-auth0-session';
import { environment } from 'environments/environment';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCommentStore } from 'stores/CommentStore';
import { getSnackbarStore } from 'stores/SnackBarStore';
import { initLogRocket } from 'third-party-integrations/log-rocket';
import { validateRedirectUri } from 'ui/apps/shared/auth0/auth0-login';
import { Spinner } from 'ui/atoms';
import { initializeLoggedInUser } from 'ui/routing/router-util';
import { RouteType } from 'ui/routing/routes';
import { getRedirectURICache } from 'util/auth0-storage.util';
import { getUserStore } from '../../stores/UserStore';
import { CrbRoute } from './route-crb';
import { FiRoute } from './route-fi';
import { GcvRoute } from './route-gcv';

const Login = React.lazy(() => import('../apps/shared/auth0/auth0-login'));

export interface RouteProps extends Record<string, unknown> {
  path: string;
  routeType: RouteType;
}

const AppRouter = () => {
  const navigate = useNavigate();
  const rollbar = useRollbar();

  const userStore = getUserStore();
  const userApi = new UsersApi();
  const commentStore = getCommentStore();
  const snackbarStore = getSnackbarStore();

  const [isLoggedInUserLoading, setIsLoggedInUserLoading] = React.useState(true);
  const [isOrgLoading, setIsOrgLoading] = React.useState(false);

  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

  const location = useLocation();

  const loadLoggedInUser = (userId: string) => {
    if (userId && !userStore.isLoggedIn) {
      userStore
        .load(userId)
        .then((user) => {
          if (user.invitation_status === 'archived') {
            snackbarStore.showErrorSnackbarMessage('Something went wrong. We were unable to log you in.');
            navigate('/logout');
          } else {
            getUserStore().isLoggedIn = true;
          }
          setIsLoggedInUserLoading(false);
        })
        .catch((e) => {
          setIsLoggedInUserLoading(false);
          setIsOrgLoading(false);
          snackbarStore.showErrorSnackbarMessage(
            'There was an issue logging in. Please contact support if the problem persists.'
          );
          navigate('/error');
        });
    } else if (userId && userStore.isLoggedIn) {
      // when user completes tos we want to load the logged in user but user store is already loaded so we bypass the load
      setIsLoggedInUserLoading(false);
    }
  };

  const initializeUser = (token: Auth0SessionToken) => {
    ApiAuth0Session.updateToken(token);
    if (location.pathname === '/sso') {
      ApiAuth0Session.selectedApplication = ApiAuth0Session.userAccess;
    }

    // We update the token earlier this this function, ensuring userIdToken is populated
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const auth0Subject = ApiAuth0Session.userIdToken!.sub!;

    // Format: "auth0|<user_id>". Users in GCV / PQ
    if (auth0Subject.includes('auth0|')) {
      // else get user by auth0, that call does the create if not exist
      const userId = auth0Subject.split('|')[1];
      loadLoggedInUser(userId);
    } else {
      // Format: "oidc|Berkshire|00u9mkb3h53KpRsfo4x7" Users from 3rd party bank
      userApi.obtainSsoUser(auth0Subject).then((ssoUser) => {
        loadLoggedInUser(ssoUser.id);
      });
    }
  };

  const cacheRedirectUrl = () => {
    const redirect_uri = new URLSearchParams(location.search).get('redirect_uri');
    if (redirect_uri && location.pathname === '/authorize' && validateRedirectUri(redirect_uri)) {
      getRedirectURICache().cacheRedirectURI(redirect_uri);
    }

    const returnTo_uri = new URLSearchParams(location.search).get('returnTo');
    if (returnTo_uri && location.pathname === '/logout' && validateRedirectUri(returnTo_uri)) {
      getRedirectURICache().cacheReturnToURI(returnTo_uri);
    }
  };

  React.useEffect(() => {
    // reset the current comment if comments are not open on page navigation
    return () => {
      if (!commentStore.commentsOpen) {
        commentStore.setCurrentPost(null);
      }
    };
  }, [location]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const isInCypressTestMode = environment.env !== GreenCheckAwsEnvironmentName.PROD && window.Cypress;

  if (isInCypressTestMode) {
    // When testing via Cypress, use programmatic login
    // https://docs.cypress.io/guides/end-to-end-testing/auth0-authentication#Adapting-the-front-end
    React.useEffect(() => {
      const auth0 = JSON.parse(localStorage.getItem('auth0Cypress')!);
      if (auth0) {
        initializeUser(auth0.body);
        setIsOrgLoading(true);
      } else {
        setIsLoggedInUserLoading(false);
        navigate('/');
      }
    }, []);
  } else {
    React.useEffect(() => {
      if (isLoading) {
        return;
      }

      if (window.location.href.indexOf('logout') > -1) {
        cacheRedirectUrl();
        setIsLoggedInUserLoading(false);
        return;
      }

      if (!isLoading && isAuthenticated) {
        getAccessTokenSilently({ detailedResponse: true }).then((token) => {
          initializeUser(token);
        });
      } else {
        cacheRedirectUrl();
        setIsLoggedInUserLoading(false);
        navigate('/');
      }
    }, [isAuthenticated, isLoading]);
  }

  React.useEffect(() => {
    const loadData = async () => {
      const load = async (url: URL, app: GreenCheckDirectApplication) => {
        // Call the get pq user session endpoint GET `/auth/pq-user-session`
        // Response has `sid` and `state` that we need to add as query params
        // TODO come back and remove session token properties once released, should only send sid and state
        const pqUserSession: PayqwickUserSession = await userApi.getPqUserSession(app);
        // If the backend is returning the sid and state then use then, otherwise use the old components
        if (pqUserSession && pqUserSession.sid && pqUserSession.state) {
          /** Delete any existing state or sid query param */
          url.searchParams.delete('state');
          url.searchParams.delete('sid');
          /** Add the query params from the pq api */
          url.searchParams.append('sid', pqUserSession.sid);
          url.searchParams.append('state', pqUserSession.state);

          const angularUrl = url.toString().replace('/%23/', '/#/');
          console.log(`Redirecting to: ${angularUrl}`);
          setIsLoggedInUserLoading(false);
          window.location.replace(angularUrl);
        } else {
          snackbarStore.showErrorSnackbarMessage(
            'Unable to connect at this time. Please contact support if the problem persists.'
          );
        }
      };

      if (userStore.isLoaded && !isLoading) {
        if (ApiAuth0Session.selectedApplication === GCApplicationAccess.All) {
          // If the user has access to both, redirect to CYOA.
          setIsLoggedInUserLoading(false);
          navigate('/sso');
        } else if (
          ApiAuth0Session.selectedApplication === GCApplicationAccess.PQ ||
          ApiAuth0Session.selectedApplication === GCApplicationAccess.GCD
        ) {
          const cache = getRedirectURICache();
          // If the user only has access to PQ, send them there, along with the token.
          const url = cache.getPayQwickRedirectURI(ApiAuth0Session.selectedApplication);
          cache.clearCache();
          const app =
            ApiAuth0Session.selectedApplication === GCApplicationAccess.GCD
              ? GreenCheckDirectApplication.GreenCheckDirect
              : GreenCheckDirectApplication.Payqwick;
          await load(url, app);
        } else if (ApiAuth0Session.selectedApplication === GCApplicationAccess.GCV) {
          initializeLoggedInUser(navigate, userStore, setIsOrgLoading, location.pathname);
        }
      }
    };
    loadData();
  }, [userStore.isLoaded, ApiAuth0Session.selectedApplication]);

  React.useEffect(() => {
    initLogRocket(rollbar);
  }, []);

  if (isLoggedInUserLoading || userStore.isLoading || isOrgLoading) {
    return (
      <Spinner
        text={
          isLoggedInUserLoading || userStore.isLoading ? 'Logging in...' : 'Loading business information...'
        }
      />
    );
  }
  const path = location.pathname;

  const RoutesRender = () => {
    if (path.includes('/crb/')) {
      return <CrbRoute path={path} />;
    } else if (path.includes('/fi/')) {
      return <FiRoute path={path} />;
    } else if (path.includes('/gcv/')) {
      return <GcvRoute />;
    } else {
      return <Login />;
    }
  };

  return <RoutesRender />;
};

export default observer(AppRouter);
